import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BreadcrumbProvider } from "./components/pages/main/BreadcrumbContext";
import { HelmetProvider } from "react-helmet-async";
import { ModalProvider } from "./components/maincomponents/ModalContext";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BreadcrumbProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </BreadcrumbProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
