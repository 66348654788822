import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { Button, Row, Col, Carousel } from "react-bootstrap";
import swal from "sweetalert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SafeHtmlComponent from "./SafeHtmlComponent";
import { Link } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa6";

import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaPinterestSquare,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

import { FaSquareXTwitter } from "react-icons/fa6";

import Spinner from "./Spinner";

import { useBreadcrumb } from "./BreadcrumbContext";
import Breadcrumbs from "./Breadcrumbs";
import { ModalContext } from "../../maincomponents/ModalContext";
import { Helmet } from "react-helmet";

const ProductDetail = () => {
  const { productId, slug } = useParams();
  const [productData, setProductData] = useState(null);
  const [mainCarouselIndex, setMainCarouselIndex] = useState(0);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const { updateBreadcrumbs } = useBreadcrumb();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const { showModal, setShowModal } = useContext(ModalContext);

  const handleOpenModal = () => setShowModal(true);

  const handleGetQuote = async () => {
    if (!isLoggedIn) {
      swal({
        title: "Login Required",
        text: "Please login to get a quote.",
        icon: "warning",
        buttons: {
          cancel: "Close",
        },
        dangerMode: true,
      }).then((willLogin) => {
        if (willLogin) {
          window.location.href = "/login";
        }
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://saaskin.com/api/api/get-quote/",
        {
          product_id: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      swal({
        title: "Quote Sent",
        text: response.data.message,
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      console.error("There was an error sending the quote request!", error);

      const errorMessage =
        error.response?.data?.detail ||
        "Failed to send quote request. Please try again later.";

      swal({
        title: "Error",
        text: errorMessage,
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    setIsLoggedIn(!!accessToken);

    axios
      .get(`https://saaskin.com/api/api/product_details/${productId}/${slug}/`)
      // const response = await fetch(`/api/products/${productId}?slug=${slug}`);
      .then((response) => {
        const { data } = response;
        setProductData(data);
        setShowIframe(data.images[0].video_url !== "");
        setIframeUrl(data.images[0].video_url);
        setLoading(false);

        if (response.data) {
          const category = response.data.category;
          const subcategory = response.data.subcategory;
          const product_type = response.data.product_type;
          const brand = response.data.brand;

          const initialBreadcrumbs = [
            { name: "Home", path: "/" },
            { name: category.name, path: `/category` },
            { name: subcategory.name, path: `/subcategory/${category.id}` },
            {
              name: product_type.name,
              path: `/product-type/${subcategory.id}`,
            },
            { name: brand.name, path: `/brand/${product_type.id}` },
            {
              name: response.data.name,
              path: `/brand-product/${brand.id}/${product_type.id}`,
            },
            {
              name: "",
              path: `/product-detail/${productId}/${slug}`,
            },
          ];

          updateBreadcrumbs(initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false);
        swal({
          title: "Error",
          text: "There was an issue loading the product details. Please try again later.",
          icon: "error",
          button: "OK",
        });
      });
  }, [productId, slug]);

  const handleThumbnailClick = (index, videoUrl) => {
    setShowIframe(videoUrl !== "");
    setMainCarouselIndex(index);
    setIframeUrl(videoUrl);
  };

  const handleShareWhatsApp = () => {
    if (productData) {
      const shareText = `
        SAASKIN Corporation - Your Trusted Healthcare Partner
  
        Check out this product on Saaskin:
  
        ${productData.name}
  
       
        Link: ${window.location.href}
      `;

      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText
      )}`;
      window.open(whatsappUrl, "_blank");
    }
  };

  const handleShare = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <Spinner />;
  }

  const relatedsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: productData ? productData.name : "Product",
    image:
      productData && productData.images.length > 0
        ? productData.images[0].image
        : "../../images/saaskin_title_logo.png",
    description: productData
      ? productData.description
      : "Product details and specifications",
    brand: {
      "@type": "Brand",
      name: "SAASKIN Corporation",
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "USD",
      price: productData ? productData.price : "0",
    },
  };

  return (
    <div>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <Helmet>
        <title>Buy METROHM Titrators Online</title>
        <meta
          name="description"
          content={`Explore products in ${productData.name}. Discover a wide range of high-quality products tailored to your needs.`}
        />
        <meta
          name="keywords"
          content={`${productData.name},Buy METROHM Titrators Online, SAASKIN Corporation, Healthcare products`}
        />
        <link
          rel="canonical"
          href={`https://saaskin.com/api/api/product_details/${productId}/${slug}/`}
        />

        <meta property="og:title" content={productData.name} />
        <meta
          property="og:description"
          content={`Explore ${productData.name}. Discover a wide range of high-quality products from SAASKIN Corporation.`}
        />
        <meta
          property="og:image"
          // content="https://saaskin.com/images/saaskin_title_logo.png" // Ensure the full URL for OG image
          content={`${productData.images[0].image}`}
        />
        <meta
          property="og:url"
          content={`https://saaskin.com/api/api/product_details/${productId}/${slug}/`}
        />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SAASKIN Corporation",
            "url": "https://saaskin.com",
            "logo": "https://saaskin.com/images/saaskin_title_logo.png",
            "sameAs": [
              "https://www.facebook.com/yourpage",
              "https://www.instagram.com/yourprofile",
              "https://www.linkedin.com/company/yourcompany",
              "https://twitter.com/yourprofile"
            ]
          }`}
        </script>
      </Helmet>
      <div className="container">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <Row>
        <Col md={6}>
          <div className="mt-2">
            <Col
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Carousel
                activeIndex={mainCarouselIndex}
                onSelect={(index) => setMainCarouselIndex(index)}
                indicators={false}
              >
                {showIframe ? (
                  <Carousel.Item key="iframe" style={{ marginTop: "40px" }}>
                    <iframe
                      title="product-video"
                      width="500"
                      height="300"
                      src={iframeUrl}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </Carousel.Item>
                ) : (
                  productData.images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 mt-5"
                        src={image.image}
                        alt={`Slide ${index}`}
                        style={{
                          maxHeight: "300px",
                          objectFit: "contain",
                        }}
                      />
                    </Carousel.Item>
                  ))
                )}
              </Carousel>
            </Col>
          </div>
          <Col md={12} className="mt-4">
            <Carousel indicators={false} controls={false} interval={null}>
              <Carousel.Item>
                <div className="d-flex justify-content-center flex-wrap">
                  {productData.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.image}
                      alt={`Thumbnail ${index}`}
                      className="img-thumbnail m-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                      onClick={() =>
                        handleThumbnailClick(index, image.video_url)
                      }
                    />
                  ))}
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Col>

        <Col md={5}>
          {productData && (
            <div className="container desc mt-5">
              <h2>
                <SafeHtmlComponent htmlContent={productData.name} />
              </h2>
              <Link
                to="#"
                style={{ textDecoration: "none" }}
                onClick={handleGetQuote}
              >
                Get Latest Price
              </Link>
              <hr />
              {isLoggedIn ? (
                productData.document ? (
                  <a
                    href={productData.document}
                    style={{ textDecoration: "none", fontSize: "20px" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaRegFilePdf style={{ color: "#e33d3d" }} /> Product
                    Brochure
                  </a>
                ) : (
                  <p>No brochure available.</p>
                )
              ) : (
                <p>
                  <FaRegFilePdf
                    style={{ color: "#e33d3d", fontSize: "25px" }}
                  />{" "}
                  Please <Link onClick={handleOpenModal}>login</Link> to see the
                  product brochure.
                </p>
              )}

              <br />
              <table className="table" style={{ backgroundColor: "#e2e1e1" }}>
                <tbody style={{ backgroundColor: "#e2e1e1" }}>
                  {productData.specifications.map((spec, index) => (
                    <tr key={index}>
                      <td>{spec.key}</td>
                      <td>{spec.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p style={{ textAlign: "justify" }}>
                <SafeHtmlComponent htmlContent={productData.description} />
              </p>
              <div style={{ display: "flex", gap: "20px" }}>
                <Button
                  variant="success"
                  id="GetQuotebtn"
                  onClick={handleGetQuote}
                >
                  Get Quote
                </Button>

                <Link to="tel:+919940116677">
                  <Button variant="outline-primary" id="makecallbtn">
                    Make Call
                  </Button>
                </Link>
                <Link
                  to="https://wa.me/+919940116677"
                  target="_blank"
                  className="social-link"
                >
                  <Button variant="success" id=" ChatNow">
                    <IoLogoWhatsapp
                      style={{ fontSize: "35px", padding: "0px 5px" }}
                    />
                    Chat Now
                  </Button>
                </Link>
              </div>
              <div
                className="d-flex flex-row mt-3"
                style={{ marginLeft: "10px", marginTop: "-3px" }}
              >
                <p style={{ display: "flex", marginTop: "-3px" }}>Share:</p>
                <FaFacebook
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShare(
                      `https://www.facebook.com/sharer/sharer.php?u=https://saaskin.com/product-detail/${productId}/${slug}`
                    )
                  }
                />
                <FaInstagramSquare
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShare(
                      `https://www.instagram.com/?url=https://saaskin.com/product-detail/${productId}/${slug}`
                    )
                  }
                />
                <FaLinkedin
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShare(
                      `https://www.linkedin.com/sharing/share-offsite/?url=https://saaskin.com/product-detail/${productId}/${slug}`
                    )
                  }
                />
                <FaSquareXTwitter
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShare(
                      `https://twitter.com/intent/tweet?url=https://saaskin.com/product-detail/${productId}/${slug}`
                    )
                  }
                />
                <FaPinterestSquare
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShare(
                      `https://www.pinterest.com/pin/create/button/?url=https://saaskin.com/product-detail/${productId}/${slug}`
                    )
                  }
                />
                <IoLogoWhatsapp
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleShareWhatsApp}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>

      <h1 style={{ textAlign: "center" }}>Related Products</h1>
      <div className="container mb-2">
        {productData.related_products.length > 0 && (
          <Row md={12} lg={10} className="mt-2">
            {productData.related_products.length > 1 ? (
              <Slider {...relatedsettings}>
                {productData.related_products.map((relatedProduct) => (
                  <div key={relatedProduct.id}>
                    <Col>
                      <Link
                        to={`/product-detail/${relatedProduct.id}/${relatedProduct.slug}`}
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        <div
                          className="card"
                          style={{
                            width: "90%",
                            height: "300px",
                            cursor: "pointer",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <img
                            src={relatedProduct.image}
                            alt={relatedProduct.name}
                            className="card-img-top"
                            style={{ objectFit: "contain", height: "220px" }}
                          />
                          <div className="card-body">
                            <p
                              className="card-text"
                              style={{ textAlign: "center", height: "80px" }}
                            >
                              <SafeHtmlComponent
                                htmlContent={relatedProduct.name}
                              />
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </div>
                ))}
              </Slider>
            ) : (
              <Col md={3} className="mt-4">
                <Link
                  to={`/product-detail/${productData.related_products[0].id}/${productData.related_products[0].slug}`}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      height: "300px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={productData.related_products[0].image}
                      alt={productData.related_products[0].name}
                      className="card-img-top m-2"
                      style={{
                        width: "250px",
                        height: "200px",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                    />
                    <div className="card-body">
                      <p
                        className="card-text"
                        style={{ textAlign: "center", height: "80px" }}
                      >
                        <SafeHtmlComponent
                          htmlContent={productData.related_products[0].name}
                        />
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import Header from "../../maincomponents/Header";
// import Footer from "../../maincomponents/Footer";
// import { Button, Row, Col, Carousel } from "react-bootstrap";
// import swal from "sweetalert";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import SafeHtmlComponent from "./SafeHtmlComponent";
// import { Link } from "react-router-dom";
// import { FaRegFilePdf } from "react-icons/fa6";
// import { Helmet, HelmetProvider } from "react-helmet-async";

// import {
//   FaFacebook,
//   FaInstagramSquare,
//   FaLinkedin,
//   FaPinterestSquare,
// } from "react-icons/fa";
// import { IoLogoWhatsapp } from "react-icons/io";

// import { FaSquareXTwitter } from "react-icons/fa6";

// import Spinner from "./Spinner";

// import { useBreadcrumb } from "./BreadcrumbContext";
// import Breadcrumbs from "./Breadcrumbs";

// const ProductDetail = () => {
//   const { productId, slug } = useParams();
//   const [productData, setProductData] = useState(null);
//   const [mainCarouselIndex, setMainCarouselIndex] = useState(0);
//   const [showIframe, setShowIframe] = useState(false);
//   const [iframeUrl, setIframeUrl] = useState("");
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const { updateBreadcrumbs } = useBreadcrumb();
//   const [breadcrumbs, setBreadcrumbs] = useState([]);

//   const [showModal, setShowModal] = useState(false);

//   const handleOpenModal = () => setShowModal(true);

//   const handleGetQuote = async () => {
//     if (!isLoggedIn) {
//       swal({
//         title: "Login Required",
//         text: "Please login to get a quote.",
//         icon: "warning",
//         buttons: {
//           cancel: "Close",
//         },
//         dangerMode: true,
//       }).then((willLogin) => {
//         if (willLogin) {
//           window.location.href = "/login";
//         }
//       });
//       return;
//     }

//     try {
//       const response = await axios.post(
//         "https://saaskin.com/api/api/get-quote/",
//         {
//           product_id: productId,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//         }
//       );
//       swal({
//         title: "Quote Sent",
//         text: response.data.message,
//         icon: "success",
//         button: "OK",
//       });
//     } catch (error) {
//       console.error("There was an error sending the quote request!", error);

//       const errorMessage =
//         error.response?.data?.detail ||
//         "Failed to send quote request. Please try again later.";

//       swal({
//         title: "Error",
//         text: errorMessage,
//         icon: "error",
//         button: "OK",
//       });
//     }
//   };

//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     setIsLoggedIn(!!accessToken);

//     axios
//       .get(`https://saaskin.com/api/api/product_details/${productId}/${slug}/`)
//       // const response = await fetch(`/api/products/${productId}?slug=${slug}`);
//       .then((response) => {
//         const { data } = response;
//         setProductData(data);
//         setShowIframe(data.images[0].video_url !== "");
//         setIframeUrl(data.images[0].video_url);
//         setLoading(false);

//         if (response.data) {
//           const category = response.data.category;
//           const subcategory = response.data.subcategory;
//           const product_type = response.data.product_type;
//           const brand = response.data.brand;

//           const initialBreadcrumbs = [
//             { name: "Home", path: "/" },
//             { name: category.name, path: `/category` },
//             { name: subcategory.name, path: `/subcategory/${category.id}` },
//             {
//               name: product_type.name,
//               path: `/product-type/${subcategory.id}`,
//             },
//             { name: brand.name, path: `/brand/${product_type.id}` },
//             {
//               name: response.data.name,
//               path: `/brand-product/${brand.id}/${product_type.id}`,
//             },
//             {
//               name: "",
//               path: `/product-detail/${productId}/${slug}`,
//             },
//           ];

//           updateBreadcrumbs(initialBreadcrumbs);
//           setBreadcrumbs(initialBreadcrumbs);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//         setLoading(false);
//       });
//   }, [productId, slug]);

//   const handleThumbnailClick = (index, videoUrl) => {
//     setShowIframe(videoUrl !== "");
//     setMainCarouselIndex(index);
//     setIframeUrl(videoUrl);
//   };

//   const handleShareWhatsApp = () => {
//     if (productData) {
//       const shareText = `Check out this product on Saaskin:\n\n${productData.name}\n\n${productData.description}\n\nLink: ${window.location.href}\n\nPlease download and attach the image below when sharing on WhatsApp.`;

//       // Get the product image URL
//       const shareImageUrl =
//         productData.images.length > 0
//           ? productData.images[0].image
//           : "images/saaskin_title_logo.png";

//       const whatsappMessage = `${shareText}\n\n[Image] ${shareImageUrl}`;

//       const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//         whatsappMessage
//       )}`;

//       window.open(whatsappUrl, "_blank");

//       const downloadLink = document.createElement("a");
//       downloadLink.href = shareImageUrl;
//       downloadLink.download = "product-image.jpg";
//       downloadLink.click();
//     }
//   };

//   const handleShare = (url) => {
//     window.open(url, "_blank", "noopener,noreferrer");
//   };

//   if (loading) {
//     return <Spinner />;
//   }

//   const relatedsettings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     dots: false,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <HelmetProvider>
//       <div>
//         <Header showModal={showModal} setShowModal={setShowModal} />
//         <Helmet>
//           <title>
//             {productData
//               ? `${productData.name} - SAASKIN Corporation`
//               : "Product Details"}
//           </title>
//           <meta
//             name="description"
//             content={
//               productData
//                 ? productData.description
//                 : "Product details and specifications"
//             }
//           />
//           <meta
//             property="og:title"
//             content={
//               productData
//                 ? `${productData.name} - SAASKIN Corporation`
//                 : "Product Details"
//             }
//           />
//           <meta
//             property="og:description"
//             content={
//               productData
//                 ? productData.description
//                 : "Product details and specifications"
//             }
//           />
//           <meta
//             property="og:image"
//             content={
//               productData && productData.images.length > 0
//                 ? productData.images[0].image
//                 : "https://saaskin.com/images/saaskin_title_logo.png"
//             }
//           />
//           <meta
//             property="og:url"
//             content={`https://saaskin.com/product-detail/${productId}/${slug}`}
//           />
//           <meta property="og:type" content="product" />
//           <meta property="twitter:card" content="summary_large_image" />
//           <meta
//             property="twitter:title"
//             content={
//               productData
//                 ? `${productData.name} - SAASKIN Corporation`
//                 : "Product Details"
//             }
//           />
//           <meta
//             property="twitter:description"
//             content={
//               productData
//                 ? productData.description
//                 : "Product details and specifications"
//             }
//           />
//           <meta
//             property="twitter:image"
//             content={
//               productData && productData.images.length > 0
//                 ? productData.images[0].image
//                 : "https://saaskin.com/images/saaskin_title_logo.png"
//             }
//           />
//         </Helmet>
//         <div className="container">
//           <Breadcrumbs breadcrumbs={breadcrumbs} />
//         </div>
//         <Row>
//           <Col md={6}>
//             <div className="mt-2">
//               <Col
//                 md={12}
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//               >
//                 <Carousel
//                   activeIndex={mainCarouselIndex}
//                   onSelect={(index) => setMainCarouselIndex(index)}
//                   indicators={false}
//                 >
//                   {showIframe ? (
//                     <Carousel.Item key="iframe" style={{ marginTop: "40px" }}>
//                       <iframe
//                         title="product-video"
//                         width="500"
//                         height="300"
//                         src={iframeUrl}
//                         frameBorder="0"
//                         allowFullScreen
//                       />
//                     </Carousel.Item>
//                   ) : (
//                     productData.images.map((image, index) => (
//                       <Carousel.Item key={index}>
//                         <img
//                           className="d-block w-100 mt-5"
//                           src={image.image}
//                           alt={`Slide ${index}`}
//                           style={{
//                             maxHeight: "300px",
//                             objectFit: "contain",
//                           }}
//                         />
//                       </Carousel.Item>
//                     ))
//                   )}
//                 </Carousel>
//               </Col>
//             </div>
//             <Col md={12} className="mt-4">
//               <Carousel indicators={false} controls={false} interval={null}>
//                 <Carousel.Item>
//                   <div className="d-flex justify-content-center flex-wrap">
//                     {productData.images.map((image, index) => (
//                       <img
//                         key={index}
//                         src={image.image}
//                         alt={`Thumbnail ${index}`}
//                         className="img-thumbnail m-2"
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           cursor: "pointer",
//                           objectFit: "contain",
//                         }}
//                         onClick={() =>
//                           handleThumbnailClick(index, image.video_url)
//                         }
//                       />
//                     ))}
//                   </div>
//                 </Carousel.Item>
//               </Carousel>
//             </Col>
//           </Col>

//           <Col md={5}>
//             {productData && (
//               <div className="container desc mt-5">
//                 <h2>
//                   <SafeHtmlComponent htmlContent={productData.name} />
//                 </h2>
//                 <Link
//                   to="#"
//                   style={{ textDecoration: "none" }}
//                   onClick={handleGetQuote}
//                 >
//                   Get Latest Price
//                 </Link>
//                 <hr />
//                 {isLoggedIn ? (
//                   productData.document ? (
//                     <a
//                       href={productData.document}
//                       style={{ textDecoration: "none", fontSize: "20px" }}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <FaRegFilePdf style={{ color: "#e33d3d" }} /> Product
//                       Brochure
//                     </a>
//                   ) : (
//                     <p>No brochure available.</p>
//                   )
//                 ) : (
//                   <p>
//                     <FaRegFilePdf
//                       style={{ color: "#e33d3d", fontSize: "25px" }}
//                     />{" "}
//                     Please <Link onClick={handleOpenModal}>login</Link> to see
//                     the product brochure.
//                   </p>
//                 )}

//                 <br />
//                 <table className="table" style={{ backgroundColor: "#e2e1e1" }}>
//                   <tbody style={{ backgroundColor: "#e2e1e1" }}>
//                     {productData.specifications.map((spec, index) => (
//                       <tr key={index}>
//                         <td>{spec.key}</td>
//                         <td>{spec.value}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <p style={{ textAlign: "justify" }}>
//                   <SafeHtmlComponent htmlContent={productData.description} />
//                 </p>
//                 <div style={{ display: "flex", gap: "20px" }}>
//                   <Button
//                     variant="success"
//                     id="GetQuotebtn"
//                     onClick={handleGetQuote}
//                   >
//                     Get Quote
//                   </Button>

//                   <Link to="tel:+919940116677">
//                     <Button variant="outline-primary" id="makecallbtn">
//                       Make Call
//                     </Button>
//                   </Link>
//                   <Link
//                     to="https://wa.me/+919940116677"
//                     target="_blank"
//                     className="social-link"
//                   >
//                     <Button variant="success" id=" ChatNow">
//                       <IoLogoWhatsapp
//                         style={{ fontSize: "35px", padding: "0px 5px" }}
//                       />
//                       Chat Now
//                     </Button>
//                   </Link>
//                 </div>
//                 <div
//                   className="d-flex flex-row mt-3"
//                   style={{ marginLeft: "10px", marginTop: "-3px" }}
//                 >
//                   <p style={{ display: "flex", marginTop: "-3px" }}>Share:</p>
//                   <FaFacebook
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={() =>
//                       handleShare(
//                         `https://www.facebook.com/sharer/sharer.php?u=https://saaskin.com/product-detail/${productId}/${slug}`
//                       )
//                     }
//                   />
//                   <FaInstagramSquare
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={() =>
//                       handleShare(
//                         `https://www.instagram.com/?url=https://saaskin.com/product-detail/${productId}/${slug}`
//                       )
//                     }
//                   />
//                   <FaLinkedin
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={() =>
//                       handleShare(
//                         `https://www.linkedin.com/sharing/share-offsite/?url=https://saaskin.com/product-detail/${productId}/${slug}`
//                       )
//                     }
//                   />
//                   <FaSquareXTwitter
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={() =>
//                       handleShare(
//                         `https://twitter.com/intent/tweet?url=https://saaskin.com/product-detail/${productId}/${slug}`
//                       )
//                     }
//                   />
//                   <FaPinterestSquare
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={() =>
//                       handleShare(
//                         `https://www.pinterest.com/pin/create/button/?url=https://saaskin.com/product-detail/${productId}/${slug}`
//                       )
//                     }
//                   />
//                   <IoLogoWhatsapp
//                     style={{
//                       color: "black",
//                       marginLeft: "10px",
//                       cursor: "pointer",
//                     }}
//                     onClick={handleShareWhatsApp}
//                   />
//                 </div>
//               </div>
//             )}
//           </Col>
//         </Row>

//         <h1 style={{ textAlign: "center" }}>Related Products</h1>
//         <div className="container mb-2">
//           {productData.related_products.length > 0 && (
//             <Row md={12} lg={10} className="mt-2">
//               {productData.related_products.length > 1 ? (
//                 <Slider {...relatedsettings}>
//                   {productData.related_products.map((relatedProduct) => (
//                     <div key={relatedProduct.id}>
//                       <Col>
//                         <Link
//                           to={`/product-detail/${relatedProduct.id}/${relatedProduct.slug}`}
//                           style={{
//                             textAlign: "center",
//                             fontWeight: "bold",
//                             textDecoration: "none",
//                           }}
//                         >
//                           <div
//                             className="card"
//                             style={{
//                               width: "90%",
//                               height: "300px",
//                               cursor: "pointer",
//                               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//                             }}
//                           >
//                             <img
//                               src={relatedProduct.image}
//                               alt={relatedProduct.name}
//                               className="card-img-top"
//                               style={{ objectFit: "contain", height: "220px" }}
//                             />
//                             <div className="card-body">
//                               <p
//                                 className="card-text"
//                                 style={{ textAlign: "center", height: "80px" }}
//                               >
//                                 <SafeHtmlComponent
//                                   htmlContent={relatedProduct.name}
//                                 />
//                               </p>
//                             </div>
//                           </div>
//                         </Link>
//                       </Col>
//                     </div>
//                   ))}
//                 </Slider>
//               ) : (
//                 <Col md={3} className="mt-4">
//                   <Link
//                     to={`/product-detail/${productData.related_products[0].id}/${productData.related_products[0].slug}`}
//                     style={{
//                       textAlign: "center",
//                       fontWeight: "bold",
//                       textDecoration: "none",
//                     }}
//                   >
//                     <div
//                       className="card"
//                       style={{
//                         height: "300px",
//                         boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//                         textAlign: "center",
//                       }}
//                     >
//                       <img
//                         src={productData.related_products[0].image}
//                         alt={productData.related_products[0].name}
//                         className="card-img-top m-2"
//                         style={{
//                           width: "250px",
//                           height: "200px",
//                           cursor: "pointer",
//                           objectFit: "contain",
//                         }}
//                       />
//                       <div className="card-body">
//                         <p
//                           className="card-text"
//                           style={{ textAlign: "center", height: "80px" }}
//                         >
//                           <SafeHtmlComponent
//                             htmlContent={productData.related_products[0].name}
//                           />
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                 </Col>
//               )}
//             </Row>
//           )}
//         </div>
//         <Footer />
//       </div>
//     </HelmetProvider>
//   );
// };

// export default ProductDetail;
