import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <Header />
      <Helmet>
        <title>Cepheid GeneXpert Test Kits</title>
        <meta
          name="description"
          content="SAASKIN Corporation - Your Trusted Healthcare Partner || about us "
        />
        <meta
          name="keywords"
          content="Abbott SD Bioline, Rapid Diagnostic Test Kit, Healthcare, Medical Supplies"
        />
        <link rel="canonical" href="https://saaskin.com" />

        <meta
          property="og:title"
          content="SAASKIN Corporation - Your Trusted Healthcare Partner"
        />
        <meta
          property="og:description"
          content="Your go-to source for the latest and best products from SAASKIN Corporation. Discover a wide range of high-quality products tailored to your needs."
        />
        <meta property="og:image" content="./images/saaskin_title_logo.png" />
        <meta property="og:url" content="https://saaskin.com" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {`{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "SAASKIN Corporation",
      "url": "https://saaskin.com",
      "logo": "./images/saaskin_title_logo.png",
      "sameAs": [
        "https://www.facebook.com/yourpage",
        "https://www.instagram.com/yourprofile",
        "https://www.linkedin.com/company/yourcompany",
        "https://twitter.com/yourprofile"
      ]
    }`}
        </script>
      </Helmet>
      <Container
        fluid
        style={{
          padding: "20px",
          fontFamily: "Poppins, sans-serif",
          wordSpacing: "2px",
          lineHeight: "25px",
          fontWeight: "400",
        }}
      >
        <Row className="justify-content-center">
          <Col lg={10}>
            <p>
              <b>SAASKIN Corporation Private Limited</b> is a Healthcare and
              Life Sciences Company that provides Manufacturing, Distribution
              and Consulting of specific range of Technology Products and
              Services that meets international standards. Saaskin has three
              Business Units namely (I) <b>Manufacturing Business Unit (MBU)</b>
              , (II) <b>Distribution Business Unit (DBU)</b> and (III){" "}
              <b>Consultancy Business Unit (CBU)</b>. We are gradually grown
              over the years from a small workshops and now have manufacturing
              facilities that covers a total area of 15000 Sq. Ft and Warehouse
              distribution facility of 9000 Sq. Ft.
            </p>
            <Image
              src="./images/abt_banner.jpg"
              alt="img"
              fluid
              style={{ marginTop: "20px" }}
            />
            <h6 style={{ marginTop: "20px" }}>
              <b>About Us:</b>
            </h6>
            <p>
              SAASKIN Started as a one –room hospital furniture fabrication shop
              in Guindy ,Chennai in 2007 and has since grown to a 24000 sq-Ft
              Facility that provides Manufacturing , Distribution and consulting
              Services in the field of Healthcare, Medical and Life Sciences to
              different markets across globe. Coupled with more than a decade of
              experience and in-depth industry Knowledge and wide network of
              supply Chain, we have created a niche for ourselves in Indian as
              well as international market.
            </p>
            <h6>
              <b>VISION:</b>
            </h6>
            <ul>
              <li>
                To become the most trusted global organization by providing
                Quality Products and Reliable Services to our Customer Partners
                around the world.
              </li>
            </ul>
            <h6>
              <b>MISSION:</b>
            </h6>
            <ul>
              <li>
                To exceed our Customers expectations in Quality, Delivery and
                cost through continuous improvement and customer collaboration
              </li>
            </ul>
            <h6>
              <b>VALUES:</b>
            </h6>
            <ul>
              <li>
                <b>Leadership:</b> Know the way, Go the way and Show the way
              </li>
              <li>
                <b>Integrity:</b> Same as in Public as you are in Private
              </li>
              <li>
                <b>Knowledge:</b> Unfold the ignorance of facts and values
              </li>
              <li>
                <b>Everyone:</b> Together we Succeed
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
