import React, { useState, useEffect } from "react";
import "./Footer.css";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { IoIosPerson } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaYoutube } from "react-icons/fa6";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";

import { fetchCategoryList } from "../utils/ApiService";
import axios from "axios";

import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Footer() {
  const isMobile = window.innerWidth <= 768;

  const [category, setCategory] = useState([]);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showfailedModal, setShowfailedModal] = useState(false);

  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const data = await fetchCategoryList();
        setCategory(data);
      } catch (error) {
        console.error("Error Fetching Category", error);
      }
    };

    getCategoryList();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://saaskin.com/api/auth/subscribe/",
        { email }
      );

      setShowModal(true);
    } catch (error) {
      console.error("Subscription failed. Please try again.");
      setModalTitle("Oops!");
      setModalMessage("Subscription failed. Please try again.");
      setShowfailedModal(true);
    }
  };

  return (
    <>
      <MDBFooter className="text-center text-lg-start footer mt-5">
        <MDBContainer className="text-center text-md-start mt-4">
          <MDBRow className="mt-3">
            <MDBCol
              md="6"
              lg="3"
              className={`mx-auto mb-4 ${isMobile ? "text-start" : ""}`}
              style={{ fontSize: "14px", lineHeight: "1" }}
            >
              <h6 className="text-uppercase fw-bold mb-4 footer-heading">
                Information
              </h6>
              <p className="hover-white">
                <Link to="/" className="text-reset text-decoration-none">
                  Home
                </Link>
              </p>
              <p className="hover-white">
                <Link to="/About" className="text-reset text-decoration-none">
                  About Us
                </Link>
              </p>
              <p className="hover-white">
                <Link to="/Careers" className="text-reset text-decoration-none">
                  Careers
                </Link>
              </p>
              <p className="hover-white">
                <Link to="/Privacy" className="text-reset text-decoration-none">
                  Privacy Policy
                </Link>
              </p>
              <p className="hover-white">
                <Link to="/Contact" className="text-reset text-decoration-none">
                  Contact Us
                </Link>
              </p>
            </MDBCol>

            <MDBCol
              md="6"
              lg="3"
              className={`mx-auto mb-4 ${isMobile ? "text-start" : ""}`}
              style={{ fontSize: "14px", lineHeight: "1" }}
            >
              <h6 className="text-uppercase fw-bold mb-4 footer-heading">
                Categories
              </h6>
              {category.map((cat, index) => (
                <p className="hover-white" key={index}>
                  <Link
                    key={cat.id}
                    to={`/subcategory/${cat.id}`}
                    className="text-reset text-decoration-none"
                  >
                    {cat.name}
                  </Link>
                </p>
              ))}
            </MDBCol>

            <MDBCol
              md="6"
              lg="3"
              className={`mx-auto mb-4 ${isMobile ? "text-start" : ""}`}
              style={{
                fontWeight: "400",
                color: "rgb(204, 204, 204)",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              <h6 className="text-uppercase fw-bold mb-4 footer-heading">
                Company Details
              </h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Saaskin Corporation Private Limited
              </p>
              <p className="footer-company-details">
                <IoIosPerson
                  style={{ color: "#ffffff", height: "30px", width: "30px" }}
                />
                Contact Person <br />
                Rajesh H (Operations Manager)
              </p>

              <p className="footer-company-address">
                <FaLocationDot
                  style={{
                    color: "#ffffff",
                    height: "20px",
                    width: "30px",
                    marginBottom: "70px",
                  }}
                />
                Address No 275 /184, First Floor,<br></br>
                Office No 2, Golden Enclave, Periyar Evr
                <br></br>Salai, P.h. Road, Kilpauk, Chennai - 600010,
                <br></br> Tamil Nadu, India <br></br>
              </p>
              <p>
                <FaLocationDot
                  style={{
                    color: "#ffffff",
                    height: "20px",
                    width: "30px",
                  }}
                />
                <Link
                  to="https://maps.app.goo.gl/18DrFuRDEKbcygYLA"
                  target="_blank"
                  style={{ color: "#ffff" }}
                >
                  Get Directions
                </Link>
              </p>

              <p className="footer-company-contact">
                <BiSolidPhoneCall
                  style={{ color: "#ffffff", height: "20px", width: "30px" }}
                />{" "}
                Call Us
                <br />
                <div className="footer-num">
                  <Link to="#" style={{ color: "white" }}>
                    +91 9940116677
                  </Link>
                  /
                  <Link to="#" style={{ color: "white" }}>
                    +91 9840819191{" "}
                  </Link>
                </div>
                <p style={{ marginLeft: "35px", fontSize: "12px" }}>
                  Working Days-(Mon to Sat)
                </p>
              </p>

              <div>
                <Link to="sms:+919940116677">
                  <button
                    style={{ marginRight: "10px", width: "%" }}
                    className="btn btn-light"
                    variant="outline-white"
                  >
                    <MdEmail
                      style={{ height: "20px", width: "30px", color: "gray" }}
                    />
                    Send SMS
                  </button>
                </Link>
                <Link to="mailto:info@saaskin.com">
                  <button className="btn btn-light">
                    <MdEmail
                      style={{ height: "20px", width: "30px", color: "gray" }}
                    />
                    Send Email
                  </button>
                </Link>
              </div>
            </MDBCol>

            <MDBCol
              md="6"
              lg="3"
              className={`mx-auto mb-4 ${isMobile ? "text-start" : ""}`}
              style={{
                fontWeight: "400",
                color: "rgb(204, 204, 204)",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              <h6 className="text-uppercase fw-bold mb-4 footer-heading">
                Follow Us
              </h6>
              <p
                className={`mx-auto mb-4 ${isMobile ? "text-start" : ""}`}
                style={{ color: "#ffffff" }}
              >
                Subscribe to our newsletter
              </p>

              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn"
                  type="submit"
                  style={{
                    width: "305px",
                    backgroundColor: "#008000",
                    color: "white",
                  }}
                  id="button-addon2"
                >
                  Subscribe
                </button>
              </form>
              <p>Stay Connect with us</p>
              <div className="social-links d-none d-md-flex align-items-center">
                <Link
                  to="https://www.facebook.com/saaskincorporation"
                  className="social-link-bottom"
                >
                  <FaFacebook />
                </Link>
                <Link
                  to="https://www.instagram.com/saaskincorporation/"
                  className="social-link-bottom"
                >
                  <FaInstagram />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/saask1n/about/"
                  className="social-link-bottom"
                >
                  <FaLinkedin />
                </Link>
                <Link to="#" className="social-link-bottom">
                  <BsTwitterX />
                </Link>
                <Link to="#" className="social-link-bottom">
                  <FaPinterest />
                </Link>
                <Link
                  to="https://www.youtube.com/@SaaskinCorporation/featured/"
                  className="social-link-bottom"
                >
                  <FaYoutube />
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="text-center text-md-start mt-4">
          <MDBRow>
            <MDBCol className="text-center py-3" style={{ fontSize: "14px" }}>
              <p className="text-white mb-0">
                &copy; {new Date().getFullYear()} Saaskin Corporation Private
                Limited. All rights reserved.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            {/* <Modal.Title></Modal.Title> */}
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h5>Subscription Successful</h5>
            <img
              src="./images/verified.gif"
              alt="Verification failed"
              style={{ width: "100px", height: "auto" }} // Adjust size as needed
            />
            <h6>Thanks for Subscribing</h6>
            <p>
              We'll keep you posted on the latest product updates, news, and
              special offers.
            </p>
            <p style={{ marginBottom: "0px" }}>STAY CONNECTED</p>
            <div className="social-links d-flex align-items-center justify-content-center">
              <Link
                to="https://www.facebook.com/saaskincorporation"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/saaskincorporation/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.linkedin.com/company/saask1n/about/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <FaLinkedin />
              </Link>
              <Link
                to="#"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <BsTwitterX />
              </Link>
              <Link
                to="#"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <FaPinterest />
              </Link>
              <Link
                to="https://www.youtube.com/@SaaskinCorporation/featured/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "20px", margin: "0 10px" }}
              >
                <FaYoutube />
              </Link>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showfailedModal} onHide={() => setShowfailedModal(false)}>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h5> Subscription Failed</h5>
            <img
              src="./images/letterx.gif"
              alt="Verification failed"
              style={{ width: "100px", height: "auto" }} // Adjust size as needed
            />
            <h6>Please try again</h6>

            <p style={{ marginBottom: "0px", marginTop: "10px" }}>
              STAY CONNECTED
            </p>
            <div className="social-links d-flex align-items-center justify-content-center">
              <Link
                to="https://www.facebook.com/saaskincorporation"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/saaskincorporation/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.linkedin.com/company/saask1n/about/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <FaLinkedin />
              </Link>
              <Link
                to="#"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <BsTwitterX />
              </Link>
              <Link
                to="#"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <FaPinterest />
              </Link>
              <Link
                to="https://www.youtube.com/@SaaskinCorporation/featured/"
                className="social-link-bottom"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "20px", color: "black", margin: "0 10px" }}
              >
                <FaYoutube />
              </Link>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-end align-items-end ">
            <Button
              variant="secondary"
              onClick={() => setShowfailedModal(false)}
              style={{
                width: "20%",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </MDBFooter>
    </>
  );
}
