import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";
import Filter from "./Filter";
import Spinner from "./Spinner";
import { Helmet } from "react-helmet";

function BrandIcon() {
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBrandData();
  });
  const fetchBrandData = async () => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/brand-icon/`
      );
      setBrandData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product type data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <Header />
      <Helmet>
        <title>{`SAASKIN Corporation -  BrandIcon`}</title>
        <meta
          name="description"
          content={`Discover the latest products from BrandIcon . Explore a wide range of healthcare products tailored to your needs.`}
        />
        <meta
          name="keywords"
          content={`Thermo Fisher CO2 Incubator price, Healthcare Products, Medical Supplies, Rapid Diagnostic Test Kit`}
        />
        <link
          rel="canonical"
          href={`https://saaskin.com/api/api/brand-icon/`}
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`SAASKIN Corporation -  BrandIcon`}
        />
        <meta
          property="og:description"
          content={`Find top products from BrandIcon, available at SAASKIN Corporation. Explore now!`}
        />
        <meta property="og:image" content="./images/saaskin_title_logo.png" />
        <meta
          property="og:url"
          content={`https://saaskin.com/api/api/brand-icon/`}
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD for structured data */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SAASKIN Corporation",
            "url": "https://saaskin.com",
            "logo": "./images/saaskin_title_logo.png",
            "sameAs": [
              "https://www.facebook.com/yourpage",
              "https://www.instagram.com/yourprofile",
              "https://www.linkedin.com/company/yourcompany",
              "https://twitter.com/yourprofile"
            ]
          }`}
        </script>
      </Helmet>
      <Container>
        <Row className="mb-5">
          <Col>
            <Row>
              {brandData.map((brand) => (
                <Col key={brand.id} xs={12} md={4} lg={2}>
                  <Link to={`/BrandIconProducts/${brand.id}`}>
                    <div
                      className="card mt-3"
                      style={{
                        padding: "10px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <img
                        className="card-img-top"
                        src={brand.brand_image}
                        alt="Card image cap"
                        style={{ height: "160px", objectFit: "contain" }}
                      />
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default BrandIcon;
