import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/pages/Homepage";
import Category from "./components/pages/main/Category";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/maincomponents/Header";
import About from "./components/pages/info/About";
import Careers from "./components/pages/info/Careers";
import Privacy from "./components/pages/info/Privacy";
import Contact from "./components/pages/info/Contact";
import Subcategory from "./components/pages/main/SubCategory";
import ProductType from "./components/pages/main/ProductType";
import Brand from "./components/pages/main/Brand";
import BrandProduct from "./components/pages/main/BrandProduct";
import ProductDetail from "./components/pages/main/ProductDetail";
import Filter from "./components/pages/main/Filter";
import SearchComponent from "./components/pages/main/SearchComponent";
import BrandIcon from "./components/pages/main/BrandIcon";
import BrandIconProducts from "./components/pages/main/BrandIconProducts";
import { PasswordReset } from "./components/maincomponents/PasswordReset";
import Blog from "./components/pages/info/Blog";
import Blogdetail from "./components/pages/info/Blogdetail";
import Spinner from "./components/pages/main/Spinner";
import Forgetpassword from "./components/pages/main/Forgetpassword";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <BrowserRouter>
        <Helmet>
          <title>SAASKIN Corporation - Your Trusted Healthcare Partner</title>
          <meta
            name="description"
            content="Your go-to source for the latest and best products from SAASKIN Corporation. Discover a wide range of high-quality products tailored to your needs."
          ></meta>
          <meta
            name="keywords"
            content="Abbott SD Bioline Rapid Diagnostic Test Kit"
          ></meta>
        </Helmet>
        <Routes>
          <Route path="/Spinner" element={<Spinner />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Header" element={<Header />}></Route>
          <Route
            path="/auth/password-reset-confirm/:uid/:token"
            element={<PasswordReset />}
          />
          <Route path="/Forgetpassword" element={<Forgetpassword />} />
          <Route path="/Category" element={<Category />}></Route>
          <Route path="/subcategory/:categoryId/" element={<Subcategory />} />
          <Route
            path="/product-type/:subcategoryId/"
            element={<ProductType />}
          />
          <Route path="/brand/:product_typeId/" element={<Brand />} />
          <Route
            path="/brand-product/:brandId/:brandProductTypeId/"
            element={<BrandProduct />}
          />
          <Route
            path="/product-detail/:productId/:slug/"
            element={<ProductDetail />}
          />

          <Route path="/fliter" element={<Filter />}></Route>
          <Route path="/Brand" element={<Brand />}></Route>
          <Route path="/BrandIcon" element={<BrandIcon />}></Route>
          <Route
            path="/BrandIconProducts/:brandId"
            element={<BrandIconProducts />}
          ></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Blog" element={<Blog />}></Route>
          <Route path="/Blogdetail/:postId" element={<Blogdetail />}></Route>
          <Route path="/Careers" element={<Careers />}></Route>
          <Route path="/Privacy" element={<Privacy />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/SearchComponent" element={<SearchComponent />}></Route>
          <Route
            path="/forgetpassword/:uidb64/:token"
            element={<Forgetpassword />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
