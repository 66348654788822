// ModalContext.js
import React, { createContext, useState } from "react";

// Create the context
export const ModalContext = createContext();

// Create the provider component
export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ModalContext.Provider value={{ showModal, setShowModal }}>
      {children}
    </ModalContext.Provider>
  );
};
